<template>
    <b-card :title="title" >

        <table class="table inovice-detail" >
          <thead>

          <tr>
              <th>محصول</th>
              <th>قیمت</th>
              <th> نوع تخفیف </th>
              <th>تخفیف(درصد)</th>
              <th>تعداد</th>
              <th>قیمت با تخفیف </th>
              <th>جمع کل</th>
          </tr>

          </thead>
          <tbody>

              <tr v-for="item in items.orders" :key="item.id">
                  <td>
                      <p>
                        <span v-if="item.variety">
                          <span class="d-block" style="max-width: 300px;">{{ item.variety.fullTitle }}</span>
                        </span>
                      </p>
                  </td>
                  <td>{{item.base_price ? numberFormat(item.base_price) : ''}}  تومان</td>
                  <td>
                    <p  v-if="$root.isEmptyObject( item.discountAmazing)">ندارد</p>
                    <p  v-else-if="item.discountAmazing">{{item.discountAmazing.title }}</p>
                  </td>
                  <td>{{item.discount}}</td>
                  <td>{{item.quantity}}</td>
                  <td>{{item.Total ? numberFormat(item.Total) : ''}} تومان</td>
                  <td>
                      <p>{{ item.Amount ? numberFormat(item.Amount) : ''}}  تومان</p>
                  </td>
              </tr>

          <tr v-if="items.invoice">
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td >هزینه حمل : </td>
              <td >
                <p v-if="items.invoice.delivery_amount != null">{{ numberFormat(items.invoice.delivery_amount) }}  تومان</p>
                <p v-else>رایگان</p>
              </td>
          </tr>
          <tr v-if="items.invoice">
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td >شیوه ارسال : </td>
              <td >
                <span v-if="items.invoice.shipping_method == 'post'">پست معمولی</span>
                <span v-else-if="items.invoice.shipping_method == 'pishtaz'">پست پیشتاز</span>
                <span v-else-if="items.invoice.shipping_method == 'tipax'">پست تیپاکس</span>
              </td>
          </tr>
          <tr v-if="items.invoice">
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td >کوپن تخفیف : </td>
              <td >
                <p v-if="items.invoice.coupon">{{ items.invoice.coupon.code }}</p>
                <p v-else>ندارد</p>
              </td>
          </tr>

          <tr v-if="items.invoice">
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td >تخفیف : </td>
              <td >
                <p v-if="items.invoice.discount">{{ items.invoice.discount }}</p>
                <p v-else>ندارد</p>
              </td>
          </tr>

          <tr>
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td ></td>
              <td >جمع کل : </td>
              <td ><p v-if="items.invoice">{{items.invoice.AmountOrders ? numberFormat(items.invoice.AmountOrders) : ''}}  تومان</p></td>
          </tr>

          </tbody>
      </table>
    </b-card>
</template>
<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data () {
    return {
      item: {},
      title: 'جزئیات فاکتور',

    }
  },
  computed: {
    url () {
      return '/api/admin/orders/' + this.$route.params.id
    }
  },
  mounted () {
    this.$axios.get(this.$root.baseUrl + this.url)
    .then(response => {
      this.items = response.data.data
    })
  }
}

</script>

<style>
  .inovice-detail p , .inovice-detail span, .inovice-detail td, .inovice-detail th{
    font-size: 13px !important;
  }
  .inovice-detail p{
    margin-bottom: 0;
  }


</style>
